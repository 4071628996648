import { IPagination } from 'components/pagination';
import { IToastProps } from 'components/toast';

/* ===== Property api state typings (start) ===== */
export interface GeoJson {
  type: string
  coordinates: [number, number]
}

export interface ICustomProperty {
  inflatedPrice: number
  editedPricePerSqMeter: number
  estimatedPricePerSqMeter: number
}

export interface IProperty extends ICustomProperty {
  id: string
  title: string
  latitude: number
  longitude: number
  geometry?: GeoJson
  constructionYear: number
  price: number
  pricePerSquareMeter: number
  propertyType: string
  postcode: number
  squareMeters: number
  images: string[]
  rooms: number
  bedrooms: number
  bathrooms: number
  storages: number
  nextYearValuationValue: number
  entranceType: string
  description: string
  sales: string[]
  ads: string[]
  decodedTitle: string
  createdAt: string
  updatedAt: string
  streetName: string
  availableForSale: boolean
  landRights: string
  balcony: string
  heating: string
  constructionStatus: string
  landShare: string
  valuationStatus: string
  fireInsuranceTotalValue: number
  recentPrice: number
  recentDate: number
}

export interface IPropertyArgs {
  limit?: number
  nextToken?: string | null | undefined
  filter?: any
  page?: number
  size?: number
  id?: string
  ids?: string[]
  isOpenSearch?: boolean
  identityId?: string
}

export interface IPropertiesApiState {
  total?: number
  nextToken?: string
  items: IProperty[]
}

export interface IPropertyHits {
  _source: IProperty
}

export interface IGeoJson {
  id: string
  geometry: GeoJson
  properties?: any
}

export interface IGeoJsonResponse {
  total: number
  items: { [key: string]: IGeoJson }
}

export interface ICoordinateResponse {
  data: string
}

export interface ICoordinate {
  total: number
  items: string[]
}

export interface IAdvertisement {
  id: string
  title: string
  latitude: number
  longitude: number
  constructionYear: number
  identityFastanr: string
  price: number
  pricePerSquareMeter: number
  propertyType: string
  postcode: number
  squareMeters: number
  status: string
  whenCreated: string | number
  whenUpdated: string
  dataSource: string
  description: string
  images: string[]
  rooms: number
  bathrooms: number
  storages: number
  nextYearValuationValue: number
  entranceType: string
  createdAt: string
  updatedAt: string
  fireInsuranceTotalValue: number
  waterPipesStatus: string
  electricityStatus: string
  drainageStatus: string
  paneStatus: string
  roofStatus: string
  landRights: string
  balcony: string
  heating: string
  constructionStatus: string
  landShare: string
  valuationStatus: string
  availableForSale: boolean
}

export interface IAdvertisementResponse {
  items: IAdvertisement[]
  nextToken: string
}

export interface ISale {
  id: string
  faerslunumer: string
  title: string
  constructionYear: number
  identityFastanr: string
  price: number
  skjalanumer: string
  propertyType: string
  postcode: number
  squareMeters: number
  heinum: string
  municipalityNumber: string
  epilog: string
  einflm: string
  createdAt: string
  updatedAt: string
  nextYearValuationValue: number
  registrationDate: number
}

export interface ISaleResponse {
  items: ISale[]
  nextToken: string
}

export interface IIndexedTitle {
  [key: string]: ITitle
}
export interface ITitle {
  id: string
  title: string
  postcode?: number
}
export interface ITitleResponse {
  data: string
}
export interface ISearchSuggestionResponse {
  data: string
  searchType: string
}
export interface ISearchSuggestion {
  label: string
  value: string
  count?: number
  children?: ISearchSuggestion[]
}
/* ===== Property api state typings (end) ===== */

/* ===== Property reducer state typings (start) ===== */
interface IExtendPagination extends IPagination {
  prefetchLimit: number
  prefetchCount: number
}
export interface IPropertyReducerState {
  showListView: boolean
  showViewTypeBtn: boolean
  showConfirmPopup: boolean
  sort: { recentlySoldAt: 'desc' | 'asc' }
  mapPagination: IExtendPagination
  savelistPagination: IExtendPagination
  drawPagination: IExtendPagination
  toast: IToastProps
  mapProperties: IProperty[]
  savelistProperties: IProperty[]
  drawProperties: IProperty[]
  selectedRemoveProperty: IProperty | null
  excludedIds: string[]
}
/* ===== Property reducer state typings (end) ===== */

/* ===== Property utils typings ===== */
export const FILTER = {
  SOLD: 'Selt',
  TYPE: 'Tegund',
  SIZE: 'Stærð',
  PRICE: 'Verð',
  PRICE_PER_SQMETER: 'Fermetraverð',
  ROOM: 'Herbergi',
  BATHROOM: 'Baðherbergi',
  CONSTRUCTION_YEAR: 'Byggingarár',
  MATCHWITHADS: 'match_with_ads',
  AVAILABLEFORSALE: 'availableForSale',
} as const;

export const SOLDOUTFILTERCONFIG = {
  LAST_THREE_MONTHS: 'last 3 months',
  LAST_SIX_MONTHS: 'last 6 months',
};

export interface IGetFilterProps {
  selectedFilter?: any
  selectedAddress?: any[]
}

export const PROPERTY_TYPE_CONST: { [key: string]: string } = {
  Fjölbýlishús: 'Fjölbýlishús',
  Einbýlishús: 'Einbýlishús',
  Raðhús: 'Raðhús',
  Sumarhús: 'Sumarhús',
  'Jörð/Lóð': 'Jörð/Lóð',
  Parhús: 'Parhús',
  Atvinnuhúsn: 'Atvinnuhúsn',
  Hesthús: 'Hestahús',
  Annað: 'Annað',
  Fyrirtæki: 'Fyrirtæki',
  'Bílskúr/geymsla/skýli': 'Bílskúr/geymsla/skýli',
  'Tví/Þrí/Fjórbýli': 'Tví/Þrí/Fjórbýli',
  Hæð: 'Hæð',
  'Bátur/skip': 'Bátur/skip',
} as const;

export const ENTRANCE_TYPE_CONST: { [key: string]: string } = {
  PRIVATE: 'Einkamál',
  SHARED: 'Deilt',
  MULTIPLE: 'Margfeldi',
  TWO: 'Tveir',
} as const;

export const LAND_RIGHTS_CONST: { [key: string]: string } = {
  OWNED: 'Átti',
  RENTED: 'Leigður',
} as const;
/* ===== Property utils typings end ===== */
