import moment from 'moment';
import { PROPERTY_TYPE_CONST } from 'features/property';
import { ISavelist } from './typings';

export const getTransformedSavelistData = (data: ISavelist[] = [], queryParams: any = null) => {
  if (!data?.length) return data;
  return data?.map((item: any) => ({
    ...item,
    url: queryParams ? `/savelist/${item?.id}?${queryParams}` : `/savelist/${item?.id}`,
    title: item?.name,
    propertyType: PROPERTY_TYPE_CONST?.[item?.propertyType] || item?.propertyType,
    createdDate: moment(item?.createdAt).format('DD-MM-YYYY'),
    modifiedDate: moment(item?.updatedAt).format('DD-MM-YYYY'),
  }));
};
