import React from 'react';
import { Navigate } from 'react-router-dom';
import { setPath } from 'utils';
import { useAppSelector } from 'app/hooks';
import { IProtectedProps } from './typings';

function ProtectedRoutes({ children }: IProtectedProps) {
  const userInfo = useAppSelector((state) => state.app.userInfo);
  setPath(window?.location?.pathname);

  return userInfo?.username ? children : (
    <Navigate to="/login" />
  );
}

export default ProtectedRoutes;
