import { gql } from '@apollo/client';

export const createSavelist = gql`
  mutation createSavelist($input: CreateSavelistInput!) {
    createSavelist(input: $input) {
      id
      name
    }
  }
`;

export const updateSavelist = gql`
  mutation updateSavelist($input: UpdateSavelistInput!) {
    updateSavelist(input: $input) {
      id
      name
    }
  }
`;

export const deleteSavelist = gql`
  mutation deleteSavelist($input: DeleteSavelistInput!) {
    deleteSavelist(input: $input) {
      id
      name
    }
  }
`;
