import { createSlice } from '@reduxjs/toolkit';
import { ISavelistState } from './typings';

const initialState: ISavelistState = {
  showPropertyPopup: false,
  showShareLinkPopup: false,
  chartPlotInfo: null,
  selectedProperty: '',
  popupData: {
    name: '',
    sales: [],
    favourites: [],
    propertyType: '',
    squareMeters: 0,
    nextYearValuationValue: 0,
    fireInsuranceTotalValue: 0,
    storages: 0,
    rooms: 0,
    bedrooms: 0,
    bathrooms: 0,
    constructionYear: 0,
    postcode: 0,
    relatedPropertyIds: [],
    editedPricePerSqMeter: 0,
    addressTitle: '',
    estimatedPricePerSqMeter: 0,
    otherFeatures: [],
    createdBy: '',
    sharedId: '',
    image: '',
    propertyId: '',
  },
  pagination: {
    page: 1,
    size: 20,
    navigatedUpto: 1,
    nextTokens: [null],
    prefetchCount: 1,
    prefetchLimit: 2,
  },
  toast: {
    showToast: false,
    message: '',
    color: 'var(--color-success)',
    position: 'top-center',
    timoutInMS: 5000,
  },
  apiModifiedAt: 0,
  popupContainerRectInfo: {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  },
  showDeleteSavelistPopup: false,
  deleteProperty: null,
};

const savelistReducer = createSlice({
  name: 'savelist',
  initialState,
  reducers: {
    setShowPropertyPopup: (state, action) => {
      state.showPropertyPopup = action.payload;
    },
    setShowShareLinkPopup: (state, action) => {
      state.showShareLinkPopup = action.payload;
    },
    setChartPlotInfo: (state, action) => {
      state.chartPlotInfo = action?.payload;
    },
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action.payload;
    },
    setPopupData: (state, action) => {
      state.popupData = {
        ...state.popupData,
        ...action.payload,
      };
    },
    clearPopupData: (state) => {
      state.popupData = initialState.popupData;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination;
    },
    setToast: (state, action) => {
      state.toast = { ...state.toast, ...action.payload };
    },
    resetToast: (state) => {
      state.toast = initialState.toast;
    },
    setApiModifiedAt: (state, action) => {
      state.apiModifiedAt = action.payload;
    },
    setPopupContainerRectInfo: (state, action) => {
      state.popupContainerRectInfo = action.payload;
    },
    setShowDeleteSavelistPopup: (state, action) => {
      state.showDeleteSavelistPopup = action.payload;
    },
    setDeleteProperty: (state, action) => {
      state.deleteProperty = action.payload;
    },
  },
});

export const {
  setShowPropertyPopup,
  setShowShareLinkPopup,
  setChartPlotInfo,
  setSelectedProperty,
  setPopupData,
  clearPopupData,
  setPagination,
  resetPagination,
  setToast,
  resetToast,
  setApiModifiedAt,
  setPopupContainerRectInfo,
  setShowDeleteSavelistPopup,
  setDeleteProperty,
} = savelistReducer.actions;
export default savelistReducer.reducer;
