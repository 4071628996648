import { createSlice } from '@reduxjs/toolkit';
import { IChart } from './typings';

const initialState: IChart = {
  inflatedData: [],
  lineChartOptions: {
    pointRadius: [],
    pointHoverRadius: [],
    pointStyle: [],
  },
};

const chartReducer = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setInflatedData: (state, action) => {
      state.inflatedData = [...(action?.payload || [])];
    },
    setLineChartOptions: (state, action) => {
      state.lineChartOptions = { ...action.payload };
    },
    clearLineChartOptions: (state) => {
      state.lineChartOptions = initialState.lineChartOptions;
    },
  },
});

export const {
  setInflatedData,
  setLineChartOptions,
  clearLineChartOptions,
} = chartReducer.actions;
export default chartReducer.reducer;
