import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import appReducer from 'features/app';
import chartReducer from 'features/chart';
import filterReducer from 'features/filter';
import mapReducer from 'features/map';
import propertyReducer, { propertyApi } from 'features/property';
import propertyDetailReducer from 'features/propertyDetail';
import savelistReducer from 'features/savelist';
import { savelistApi } from 'features/savelistApi';
import searchReducer from 'features/search';
import { authApi } from 'features/auth/authApi';
import dashboardReducer, { dashboardApi } from 'features/dashboard';

export const store = configureStore({
  reducer: {
    app: appReducer,
    property: propertyReducer,
    map: mapReducer,
    search: searchReducer,
    filter: filterReducer,
    propertyDetail: propertyDetailReducer,
    savelist: savelistReducer,
    chart: chartReducer,
    dashboard: dashboardReducer,
    [propertyApi.reducerPath]: propertyApi.reducer,
    [savelistApi.reducerPath]: savelistApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      propertyApi.middleware,
      savelistApi.middleware,
      authApi.middleware,
      dashboardApi.middleware,
    );
  },
});

// enable listener behavior for the store
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
