/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://sjwpoogmundsvgtlpltkekefau.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "remaxproperty",
            "endpoint": "https://bfkdknt4e0.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "remaxsearch",
            "endpoint": "https://cuk1axtkh2.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:551b439d-8c28-47bf-bf87-26f193e032b7",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_2BRxtLVQh",
    "aws_user_pools_web_client_id": "3j1jqavjmt6ajric6vdjronre8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "remax-property165458-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Hms-34znptsjqfhi7mxvalciguhlmq-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "AdWithSale-34znptsjqfhi7mxvalciguhlmq-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "Property-34znptsjqfhi7mxvalciguhlmq-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "SaleRecord-34znptsjqfhi7mxvalciguhlmq-dev",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
