import React, {
  Suspense,
  useEffect,
} from 'react';
import { Auth, Hub } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import ProtectedRoutes from 'components/protectedRoutes';
import UnProtectedRoutes from 'components/unProtectedRoutes';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useViewPort } from 'hooks/useViewport';
import { setUserInfo } from 'features/app';
import { authApi, useLoginQuery } from 'features/auth';
import { deleteCookie, getCookie } from 'utils';
import { AppWrapper } from './App.styled';
import GlobalStlyes from './globalStyles';

const Layout = React.lazy(() => import('layouts'));
const Home = React.lazy(() => import('views/home'));
const NotFound = React.lazy(() => import('views/notFound'));
const Login = React.lazy(() => import('views/login'));
const PropertyDetail = React.lazy(() => import('views/propertyDetail'));
const Savelist = React.lazy(() => import('views/savelist'));
const SharedSavelist = React.lazy(() => import('views/sharedSavelist'));
const SavelistListView = React.lazy(() => import('views/savelist/ListView'));
const Dashboard = React.lazy(() => import('views/dashboard'));

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

function App() {
  const userInfo = useAppSelector((state) => state.app.userInfo);
  const token = getCookie('token');
  const hasToken: boolean = !!token;
  useLoginQuery({
    token: userInfo?.token,
  }, { skip: !userInfo?.token || hasToken });
  const dispatch = useAppDispatch();
  useViewPort();

  const listerner = (data: any) => {
    switch (data?.payload?.event) {
      case 'signIn': {
        const { challengeParam = {}, attributes = {} } = data?.payload?.data || {};
        const email: string = challengeParam?.userAttributes?.email
          || attributes?.email;
        const isSuperAdmin: boolean = data?.payload?.data?.signInUserSession?.accessToken
          ?.payload?.['cognito:groups']?.includes('SuperAdmins');
        dispatch(setUserInfo({
          username: data?.payload?.data?.username,
          token: data?.payload?.data?.signInUserSession?.accessToken?.jwtToken,
          isSuperAdmin,
          email,
        }));
        break;
      }
      default:
        break;
    }
  };

  const signOut = () => {
    console.log('Token has expired');
    dispatch(authApi.endpoints.logout.initiate({}));
    Auth.signOut();
  };

  const loadUser = () => {
    Auth.currentAuthenticatedUser()
      ?.then((user: any) => {
        const accessToken = user?.signInUserSession?.accessToken?.jwtToken;
        const isSuperAdmin = user?.signInUserSession?.accessToken?.payload?.['cognito:groups']
          ?.includes('SuperAdmins');
        if (token && token !== accessToken) deleteCookie('token');
        dispatch(setUserInfo({
          username: user?.username,
          email: user?.attributes?.email,
          token: accessToken,
          isSuperAdmin,
        }));
      })
      ?.catch((err: any) => {
        console.log('auth err', err);
        signOut();
      });
    Auth.currentCredentials()
      ?.then((credentials: any) => {
        dispatch(setUserInfo({
          identityId: credentials?.identityId,
        }));
      })
      ?.catch((err: any) => console.log('credential err', err));
  };

  useEffect(() => {
    Hub.listen('auth', listerner);
    loadUser();
  }, []);

  return (
    <AppWrapper className="app">
      <GlobalStlyes />
      <Router>
        <Suspense>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Layout />}>
              <Route index element={<ProtectedRoutes><Home /></ProtectedRoutes>} />
              <Route path="/props/:propID" element={<UnProtectedRoutes><PropertyDetail /></UnProtectedRoutes>} />
              <Route path="/savelist" element={<ProtectedRoutes><Savelist /></ProtectedRoutes>} />
              <Route path="/savelist/listview" element={<ProtectedRoutes><SavelistListView /></ProtectedRoutes>} />
              <Route path="/savelist/:savelistID" element={<ProtectedRoutes><Savelist /></ProtectedRoutes>} />
              <Route
                path="/savelist/:savelistID/shared/:sharedID"
                element={<UnProtectedRoutes><SharedSavelist /></UnProtectedRoutes>}
              />
              <Route path="/dashboard" element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} />
              <Route path="/*" element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </AppWrapper>
  );
}

export default App;
