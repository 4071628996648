import React from 'react';
import ReactDOM from 'react-dom/client';
import SymbolObservable from 'symbol-observable';
import { Provider } from 'react-redux';
import { store } from 'app/store';
import { Amplify } from 'aws-amplify';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import awsExports from './aws-exports';

Amplify.configure(awsExports);
console.log(SymbolObservable);

export const client = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_URI, // Replace with your Flask API GraphQL endpoint
  cache: new InMemoryCache(),
  credentials: 'same-origin',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
