import { createApi } from '@reduxjs/toolkit/dist/query/react';
import * as CustomQueries from 'graphql/customQueries';
import * as CustomMutations from 'graphql/customMutations';
import { client } from 'index';
import {
  ISavelistArgs,
  ISavelistApiState,
  ISavelist,
} from './typings';

export const savelistApi = createApi({
  reducerPath: 'savelistApi',
  keepUnusedDataFor: 60 * 60 * 24,
  baseQuery: async ({
    query,
    variables,
    queryName,
    context,
    isMutation = false,
  }) => {
    try {
      const { data } = isMutation
        ? await client.mutate({ mutation: query, variables })
        : await client.query({ query, variables, context });
      return { data: data?.[queryName] };
    } catch (err) {
      return { error: err };
    }
  },
  tagTypes: ['Savelists'],
  endpoints: (builder) => ({
    getSavelists: builder.query<ISavelistApiState, ISavelistArgs>({
      query: ({
        filter,
        nextToken,
        limit = 20,
        modifiedAt,
      }) => {
        return {
          query: CustomQueries.listSavelists,
          queryName: 'listSavelists',
          variables: {
            createdBy: filter?.createdBy,
            limit,
            nextToken,
            modifiedAt,
          },
        };
      },
      providesTags: (result) => {
        return result?.items?.length
          ? [...(result.items.map((item: ISavelist) => ({
            type: 'Savelists' as const, id: item?.id,
          })))]
          : ['Savelists'];
      },
    }),
    getSavelistById: builder.query<ISavelist, ISavelistArgs>({
      query: ({
        id,
        identityId,
        createdBy,
        modifiedAt,
      }) => {
        return {
          query: CustomQueries.getSavelist,
          queryName: 'getSavelist',
          variables: { id, createdBy, modifiedAt },
          ...(identityId ? {
            context: {
              headers: { 'X-Amz-id': identityId },
            },
          } : {}),
        };
      },
      transformResponse: (response: ISavelist) => {
        if (!response) return response;
        return {
          ...response,
          address: [response?.postcode, response?.addressTitle]?.join(' ')?.trim(),
          editedPrice: Math.round(response.editedPricePerSqMeter * response.squareMeters),
          price: Math.round(response.estimatedPricePerSqMeter * response.squareMeters),
        };
      },
    }),
    createSavelist: builder.mutation<ISavelist, ISavelistArgs>({
      query: ({ payload }) => {
        delete payload?.owner;
        delete payload?.address;
        delete payload?.price;
        delete payload?.avgInflatedPrice;
        delete payload?.editedPrice;
        delete payload?.avgInflatedPricePerSqMeter;
        delete payload?.items;
        delete payload?.__typename;
        return {
          isMutation: true,
          query: CustomMutations.createSavelist,
          queryName: 'createSavelist',
          variables: { input: payload },
        };
      },
      invalidatesTags: ['Savelists'],
    }),
    updateSavelist: builder.mutation<ISavelist, ISavelistArgs>({
      query: ({ payload }) => {
        delete payload?.owner;
        delete payload?.address;
        delete payload?.price;
        delete payload?.avgInflatedPrice;
        delete payload?.editedPrice;
        delete payload?.avgInflatedPricePerSqMeter;
        delete payload?.items;
        delete payload?.__typename;
        return {
          isMutation: true,
          query: CustomMutations.updateSavelist,
          queryName: 'updateSavelist',
          variables: { input: payload },
        };
      },
      invalidatesTags: (result) => ([
        { type: 'Savelists' as const, id: result?.id },
      ]),
    }),
    deleteSavelistById: builder.mutation<ISavelist, ISavelistArgs>({
      query: ({ id }) => {
        return {
          isMutation: true,
          query: CustomMutations.deleteSavelist,
          queryName: 'deleteSavelist',
          variables: { input: { id } },
        };
      },
      invalidatesTags: ['Savelists'],
    }),
  }),
});

export const {
  useGetSavelistsQuery,
  useGetSavelistByIdQuery,
  useCreateSavelistMutation,
  useUpdateSavelistMutation,
  useDeleteSavelistByIdMutation,
} = savelistApi;
