import moment from 'moment';
import inflationPrice from 'json/inflationPrice.json';
import { FILTER } from 'features/property';
import { LOCAL_STORAGE_KEY_CONFIGS } from 'features/app';

interface ILocalNumFormatOptProps {
  exclude?: string[]
  decimalPrecision?: number
}

interface IInflationPriceProps {
  [key: string]: number[]
}

const localStorageKeys: string[] = [];

export const isUndefined = (val: any) => val === undefined;
export const isEmptyObject = (val: any = {}) => !Object?.keys(val)?.length;

export const getLocaleNumFormat = (val: number, options?: ILocalNumFormatOptProps) => {
  const { exclude, decimalPrecision = 0 } = options || {};
  return new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: decimalPrecision || 0,
  })
    ?.formatToParts(val)
    ?.filter((x: any) => !exclude?.includes(x?.type))
    ?.map((x: any) => (x?.type === 'group' ? '.' : x?.value))
    ?.join('')
    ?.trim() || '-';
};

export const setPath = (current: string) => {
  const localData: any = localStorage.getItem('path');
  const parsedData = JSON.parse(localData);
  if (parsedData && parsedData?.current === current) return;
  localStorage.setItem('path', JSON.stringify({
    prev: parsedData?.current || '/',
    current,
  }));
};

export const getPrevPath = () => {
  const localData: any = localStorage.getItem('path');
  const parsedData = JSON.parse(localData);
  return parsedData?.prev;
};

export const getCurrentPath = () => {
  const localData: any = localStorage.getItem('path');
  const parsedData = JSON.parse(localData);
  return parsedData?.current || window?.location?.pathname;
};

export const clearUserSession = () => {
  Object?.keys(localStorage)?.forEach((key: string) => {
    if (!['path', 'filter']?.includes(key)) {
      localStorage?.removeItem(key);
    }
  });
};

export const guid = () => {
  const generator = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return [generator() + generator()]?.join('-');
};

export const getInflationPrice = ({
  year = '', price, type = '', postcode = 0,
}: {
  year: string, price: number, type?: string, postcode: number
}) => {
  const validPostcode = [
    101,
    102,
    103,
    104,
    105,
    107,
    108,
    109,
    110,
    111,
    112,
    113,
    116,
    121,
    123,
    124,
    125,
    127,
    128,
    129,
    130,
    132,
    150,
    155,
    161,
    162,
    170,
    172,
    200,
    201,
    202,
    203,
    206,
    210,
    212,
    220,
    221,
    222,
    225,
    270,
    271,
    276,
  ];
  const inflationRateConfig: any = {
    Fjölbýlishús: 0,
    Einbýlishús: 1,
    Raðhús: 0,
    Sumarhús: 3,
    'Jörð/Lóð': 0,
    Parhús: 0,
    Atvinnuhúsn: 0,
    Hesthús: 0,
    Annað: 0,
    Fyrirtæki: 0,
    'Bílskúr/geymsla/skýli': 0,
    'Tví/Þrí/Fjórbýli': 0,
    Hæð: 0,
    'Bátur/skip': 0,
  };
  const data: IInflationPriceProps = inflationPrice || {};
  if (!data?.[year] || !postcode || !price) return price;
  let inflationRateIdx: number = inflationRateConfig?.[type] || 0;
  if (inflationRateIdx === undefined) return price;
  if (!validPostcode?.includes(postcode)) inflationRateIdx = 2;
  const currentDate = [moment().year(), (moment().month() + 1)]?.join('M');
  const currentInflationRate: number = data?.[currentDate]?.[inflationRateIdx]
    || Object.values(data)[Object.keys(data).length - 1]?.[inflationRateIdx];
  const propertyInflationRate: number = data?.[year]?.[inflationRateIdx] || 0;
  return Math.round((currentInflationRate / propertyInflationRate) * price);
};

export const filterRange = (arr: any, startIdx?: number, endIdx?: number, cbk?: any) => {
  const filteredData: any = [];
  if (!arr) return filteredData;
  const length: number = arr?.length;
  for (let i = startIdx || 0; i < length; i += 1) {
    if (filteredData?.length === endIdx) break;
    if (cbk) {
      if (cbk?.(arr[i])) filteredData?.push(arr[i]);
    } else filteredData?.push(arr[i]);
  }
  return filteredData;
};

export const formatDate = (date: any, format: string = 'DD.MM.YYYY') => {
  if (!date) return date;
  if (typeof date !== 'string') return moment.unix(date).format(format);
  if (date?.indexOf('T') > -1 || date?.indexOf('-') > -1) {
    return moment.utc(date).format(format);
  }
  return moment.unix(parseInt(date, 10)).format(format);
};

export const Iterator = (start: number, end: number) => {
  const arr: number[] = [];
  const startIdx: number = start || 0;
  const endIdx: number = start === 1 ? end + 1 : end;
  for (let i = (startIdx || 0); i < endIdx; i += 1) {
    arr?.push(i);
  }
  return arr;
};

export const getPaginatedData = (data: any[] = [], page: number = 1, size: number = 10) => {
  if (!data?.length) return data;
  const startIdx: number = page === 1 ? page - 1 : (page - 1) * size;
  const endIdx: number = page * size;
  return data?.slice(startIdx, endIdx);
};

export const getLocalStorage = (key?: string) => {
  const localStorageData: any = key ? localStorage?.getItem(key) : localStorage;
  const parsedData: any = key ? JSON.parse(localStorageData) : {};
  if (key) return parsedData;

  Object?.entries(localStorageData)?.forEach(([k, v]: any) => {
    if (localStorageKeys?.includes(k)) parsedData[k] = JSON.parse(v);
  });
  return key ? parsedData?.[key] : parsedData;
};

export const setLocalStorage = (key: string, val?: any) => {
  if (!key) return;

  let tempData: any;
  switch (key) {
    case LOCAL_STORAGE_KEY_CONFIGS.EXCLUDED_PROPERTY_IDS:
    case LOCAL_STORAGE_KEY_CONFIGS.FAVOURITE_IDS:
      tempData = getLocalStorage(key) || [];
      if (Array.isArray(val)) {
        val?.forEach((id: string) => {
          if (!tempData?.includes(id)) {
            tempData?.push(id);
          }
        });
      } else {
        const isExist: boolean = (getLocalStorage(key) || [])?.includes(val);
        tempData = isExist
          ? [...(getLocalStorage(key)?.filter((item: string) => (
            item !== val
          )) || [])]
          : [...(getLocalStorage(key) || []), val];
      }
      tempData = tempData?.filter(Boolean);
      break;
    case LOCAL_STORAGE_KEY_CONFIGS.SCROLL_POS:
      tempData = {
        ...(getLocalStorage(LOCAL_STORAGE_KEY_CONFIGS.SCROLL_POS) || {}),
        ...val,
      };
      break;
    case LOCAL_STORAGE_KEY_CONFIGS.DRAW_IDS:
    case LOCAL_STORAGE_KEY_CONFIGS.URL_ID:
    case LOCAL_STORAGE_KEY_CONFIGS.LIST_VIEW:
      tempData = val;
      break;
    default:
      console.log('No Actions!');
  }

  localStorage?.setItem(key, JSON.stringify(tempData));
};

export const removeLocalStorage = (arr: string[] = []) => {
  if (!arr?.length) return;
  arr?.forEach((val: any) => {
    localStorage?.removeItem(val);
  });
};

export const isCurrentSoldAtYear = (min: number, max: number) => {
  if (!min || !max) return false;
  const currentYear = moment().year();
  const prevYear = moment().subtract('1', 'year').year();
  return min === prevYear && max === currentYear;
};

export const getTransformedSuggestionList = (data: any, keyType: string = 'key') => {
  const isArray: boolean = Array?.isArray(data);
  return (isArray ? data : Object?.entries(data))?.map((item: any) => ({
    label: isArray ? item : item?.[keyType === 'key' ? 0 : 1],
    value: isArray ? item : item?.[keyType === 'key' ? 0 : 1],
  }));
};

export const getTransformedURLParams = (data: any = {}, isReverse: boolean = false) => {
  const transformedParams: any = isReverse ? { filter: {}, sort: [] } : {};
  if (isEmptyObject(data)) return transformedParams;
  Object?.entries(data)?.forEach(([k, v]: any) => {
    switch (k) {
      case 'search':
        if (isReverse) {
          transformedParams.filter.selectedAddress = v?.split(',')
            ?.map((val: string) => ({ label: val, value: val }));
        } else transformedParams[k] = v?.map((x: any) => x?.value)?.join(',');
        break;
      case 'filter':
        if (isReverse) {
          transformedParams.filter.selectedFilter = Object?.fromEntries(
            v?.split(';')?.map((item: any) => {
              const [key, val]: any = item?.split(':') || [];
              if ([undefined, null].includes(val)) return null;
              switch (key) {
                case FILTER.AVAILABLEFORSALE:
                case FILTER.MATCHWITHADS:
                  return [key, val?.toLowerCase() === 'true'];
                case FILTER.TYPE: {
                  const arr = val?.split(',')?.filter(Boolean);
                  return arr?.length ? [key, val?.split(',')] : null;
                }
                default: {
                  if (val?.indexOf(',') < 0) return [key, val];
                  const [min, max] = val?.split(',') || [];
                  return [key, { min: parseInt(min, 10), max: parseInt(max, 10) }];
                }
              }
            })?.filter(Boolean) || [],
          );
        } else {
          transformedParams[k] = Object?.entries(v)?.map(([key, val]: any) => {
            if ([undefined, null].includes(val)) return null;
            switch (key) {
              case FILTER.AVAILABLEFORSALE:
              case FILTER.MATCHWITHADS:
                return `${key}:${val}`;
              case FILTER.TYPE:
                return `${key}:${val?.length ? val?.join(',') : ''}`;
              default:
                return typeof val === 'string' ? `${key}:${val}` : `${key}:${val?.min},${val?.max}`;
            }
          })?.filter(Boolean)?.join(';');
        }
        break;
      case 'sort':
        if (isReverse) {
          transformedParams.sort = Object?.fromEntries(
            v?.split(';')?.map((item: any) => {
              return item?.split(':') || [];
            }),
          );
        } else {
          transformedParams[k] = Object?.entries(v)?.map(([key, val]: any) => (
            `${key}:${val}`
          ))?.join(';');
        }
        break;
      case 'polygons':
        if (!v) {
          transformedParams[k] = null;
          return;
        }
        if (isReverse) {
          transformedParams[k] = {};
          v?.split(';')?.forEach((item: any) => {
            const [_k, _v] = item?.split(':') || [];
            transformedParams[k][_k] = [_v?.split(',')?.map((x: any) => {
              const [i, j] = x?.split('--') || [];
              return [parseFloat(i), parseFloat(j)];
            })];
          });
        } else {
          transformedParams[k] = Object?.entries(v)?.map(([_k, _v]: any) => (
            `${_k}:${_v?.[0]?.map((x: any) => x?.join('--'))?.join(',')}`
          ))?.join(';');
        }
        break;
      case 'zoom':
        transformedParams[k] = isReverse ? parseInt(v, 10) : v;
        break;
      case 'center':
        if (isReverse) {
          const [lat, lng] = v?.split(',') || [];
          transformedParams[k] = { lat: parseFloat(lat), lng: parseFloat(lng) };
        } else transformedParams[k] = `${v?.lat},${v?.lng}`;
        break;
      case 'boundaryCoords':
        if (isReverse) {
          transformedParams.filter[k] = Object?.fromEntries(
            v?.split(';')?.map((item: any) => {
              const [key, val] = item?.split(':') || [];
              return [key, {
                lat: parseFloat(val?.split(',')[0]),
                lng: parseFloat(val?.split(',')[1]),
              }];
            }),
          );
        } else {
          transformedParams[k] = v && Object?.entries(v)?.map(([key, val]: any) => {
            return `${key}:${val?.lat},${val?.lng}`;
          })?.join(';');
        }
        break;
      case 'urlId':
      case 'savelistID':
      case 'sharedID':
        transformedParams[k] = v;
        break;
      default:
        break;
    }
  });
  return { ...transformedParams };
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value?.split(`; ${name}=`);
  if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
  return '';
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const isValidGuid = (value: string) => {
  if (!value) return false;
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
  return regex?.test(value);
};
