import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEY_CONFIGS } from 'features/app';
import { removeLocalStorage, setLocalStorage } from 'utils';
import { IProperty, IPropertyReducerState } from './typings';

const initialState: IPropertyReducerState = {
  showListView: false,
  showViewTypeBtn: false,
  showConfirmPopup: false,
  sort: { recentlySoldAt: 'desc' },
  mapPagination: {
    page: 1,
    size: 20,
    navigatedUpto: 1,
    prefetchCount: 1,
    prefetchLimit: 2,
  },
  savelistPagination: {
    page: 1,
    size: 20,
    navigatedUpto: 1,
    prefetchCount: 1,
    prefetchLimit: 2,
  },
  drawPagination: {
    page: 1,
    size: 20,
    navigatedUpto: 1,
    prefetchCount: 1,
    prefetchLimit: 2,
  },
  mapProperties: [],
  savelistProperties: [],
  drawProperties: [],
  toast: {
    showToast: false,
    message: '',
    color: 'var(--color-success)',
    position: 'top-center',
    timoutInMS: 5000,
  },
  selectedRemoveProperty: null,
  excludedIds: [],
};

const propertyReducer = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setShowListView: (state, action) => {
      state.showListView = action?.payload;
      setLocalStorage(LOCAL_STORAGE_KEY_CONFIGS.LIST_VIEW, action?.payload === true ? 1 : 0);
    },
    setShowViewTypeBtn: (state, action) => {
      state.showViewTypeBtn = action.payload;
    },
    setShowConfirmPopup: (state, action) => {
      state.showConfirmPopup = action?.payload;
    },
    setSort: (state, action) => {
      if (action.payload) {
        state.sort = {
          ...state.sort,
          ...action.payload,
        };
      }
    },
    setMapPagination: (state, action) => {
      state.mapPagination = {
        ...state.mapPagination,
        ...action.payload,
      };
    },
    resetMapPagination: (state) => {
      state.mapPagination = initialState.mapPagination;
    },
    setSavelistPagination: (state, action) => {
      state.savelistPagination = {
        ...state.savelistPagination,
        ...action.payload,
      };
    },
    resetSavelistPagination: (state) => {
      state.savelistPagination = initialState.savelistPagination;
    },
    setDrawPagination: (state, action) => {
      state.drawPagination = {
        ...state.drawPagination,
        ...action.payload,
      };
    },
    resetDrawPagination: (state) => {
      state.drawPagination = initialState.drawPagination;
    },
    setToast: (state, action) => {
      state.toast = { ...state.toast, ...action.payload };
    },
    resetToast: (state) => {
      state.toast = initialState.toast;
    },
    setMapProperties: (state, action) => {
      const existsData = state.mapProperties?.map((item: IProperty) => item?.id) || [];
      const newData = action?.payload?.filter((item: IProperty) => {
        return !existsData?.includes(item?.id);
      }) || [];
      if (!newData?.length) return;
      state.mapProperties = [...state.mapProperties, ...newData];
    },
    removeMapProperties: (state, action) => {
      state.mapProperties = state.mapProperties?.filter((property: IProperty) => {
        return !action?.payload?.includes(property?.id);
      }) || [];
    },
    clearMapProperties: (state) => {
      state.mapProperties = initialState?.mapProperties;
    },
    setSavelistProperties: (state, action) => {
      const existsData = state.savelistProperties?.map((item: IProperty) => item?.id) || [];
      const newData = action?.payload?.filter((item: IProperty) => {
        return !existsData?.includes(item?.id);
      }) || [];
      if (!newData?.length) return;
      state.savelistProperties = [...state.savelistProperties, ...newData];
    },
    removeSavelistProperties: (state, action) => {
      state.savelistProperties = state.savelistProperties?.filter((property: IProperty) => {
        return !action?.payload?.includes(property?.id);
      }) || [];
    },
    clearSavelistProperties: (state) => {
      state.savelistProperties = initialState?.savelistProperties;
    },
    setDrawProperties: (state, action) => {
      const existsData = state.drawProperties?.map((item: IProperty) => item?.id) || [];
      const newData = action?.payload?.filter((item: IProperty) => {
        return !existsData?.includes(item?.id);
      }) || [];
      if (!newData?.length) return;
      state.drawProperties = [...state.drawProperties, ...newData];
    },
    removeDrawProperties: (state, action) => {
      state.drawProperties = state.drawProperties?.filter((property: IProperty) => {
        return !action?.payload?.includes(property?.id);
      }) || [];
    },
    clearDrawProperties: (state) => {
      state.drawProperties = initialState?.drawProperties;
    },
    setSelectedRemoveProperty: (state, action) => {
      state.selectedRemoveProperty = action?.payload;
    },
    setExcludedIds: (state, action) => {
      state.excludedIds = [
        ...state.excludedIds,
        ...(action.payload?.filter((id: string) => !state?.excludedIds?.includes(id)) || []),
      ];
      setLocalStorage(LOCAL_STORAGE_KEY_CONFIGS?.EXCLUDED_PROPERTY_IDS, state.excludedIds);
    },
    clearExcludedIds: (state) => {
      state.excludedIds = initialState?.excludedIds;
      removeLocalStorage([
        LOCAL_STORAGE_KEY_CONFIGS.EXCLUDED_PROPERTY_IDS,
        LOCAL_STORAGE_KEY_CONFIGS.FAVOURITE_IDS,
      ]);
    },
  },
});

export const {
  setShowListView,
  setShowViewTypeBtn,
  setShowConfirmPopup,
  setSort,
  setMapPagination,
  resetMapPagination,
  setSavelistPagination,
  resetSavelistPagination,
  setDrawPagination,
  resetDrawPagination,
  setToast,
  resetToast,
  setMapProperties,
  removeMapProperties,
  clearMapProperties,
  setSavelistProperties,
  removeSavelistProperties,
  clearSavelistProperties,
  setDrawProperties,
  removeDrawProperties,
  clearDrawProperties,
  setSelectedRemoveProperty,
  setExcludedIds,
  clearExcludedIds,
} = propertyReducer.actions;
export default propertyReducer.reducer;
