import { setPath } from 'utils';
import { IUnProtectedProps } from './typings';

function UnProtectedRoutes({ children }: IUnProtectedProps) {
  setPath(window?.location?.pathname);

  return children;
}

export default UnProtectedRoutes;
