export const getTransformedUsers = (users: any) => {
  if (!users?.length) return [];
  return users.map((user: any) => {
    const email: string = user?.Attributes?.find((attr: any) => attr.Name === 'email')?.Value;
    const groups: string[] = user?.Groups?.map((group: any) => group.GroupName) || [];
    return {
      id: user.Username,
      email,
      groups,
    };
  });
};
