import { createSlice } from '@reduxjs/toolkit';
import { setLocalStorage } from 'utils';
import { LOCAL_STORAGE_KEY_CONFIGS } from 'features/app';
import { IMap } from './typings';

const initialState: IMap = {
  showMap: true,
  zoom: 5,
  center: { lng: -18, lat: 65 },
  zoomLevel: {
    min: 5,
    max: 18,
  },
  polygons: {},
  drawMode: false,
  drawnCoords: {},
  selectedMarkerIds: [],
};

const mapReducer = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setShowMap: (state, action) => {
      state.showMap = action.payload;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
    setCenter: (state, action) => {
      state.center = action.payload;
    },
    setDrawMode: (state, action) => {
      state.drawMode = action.payload;
      if (!action.payload) {
        state.polygons = {};
        state.drawnCoords = {};
      }
    },
    setPolygons: (state, action) => {
      state.polygons = action.payload;
    },
    setDrawnCoords: (state, action) => {
      state.drawnCoords = {
        ...state.drawnCoords,
        ...action.payload,
      };
      setLocalStorage(
        LOCAL_STORAGE_KEY_CONFIGS?.DRAW_IDS,
        action.payload,
      );
    },
    resetDrawnCoords: (state) => {
      state.drawnCoords = {};
      setLocalStorage(LOCAL_STORAGE_KEY_CONFIGS.DRAW_IDS, {});
    },
    setSelectedMarkerIds: (state, action) => {
      state.selectedMarkerIds = action.payload;
    },
  },
});

export const {
  setShowMap,
  setZoom,
  setCenter,
  setDrawMode,
  setPolygons,
  setDrawnCoords,
  resetDrawnCoords,
  setSelectedMarkerIds,
} = mapReducer.actions;
export default mapReducer.reducer;
