export interface IUserInfo {
  username: string
  email: string
  identityId: string
  token: string
  isSuperAdmin: boolean
}

export interface IViewport {
  width: number
  mobileView: boolean
  tabView: boolean
  desktopView: boolean
}

export interface IAppState {
  userInfo: IUserInfo
  viewport: IViewport
  searchParamData: any
}

export const LOCAL_STORAGE_KEY_CONFIGS: { [key: string]: string } = {
  PATH: 'path',
  FAVOURITE_IDS: 'favouriteIds',
  EXCLUDED_PROPERTY_IDS: 'excludedPropertyIds',
  DRAW_IDS: 'drawIds',
  URL_ID: 'urlId',
  LIST_VIEW: 'listView',
  SCROLL_POS: 'scrollPosition',
} as const;
