import { createSlice, current } from '@reduxjs/toolkit';
import { getLocalStorage, getTransformedURLParams } from 'utils';
import { IAppState, LOCAL_STORAGE_KEY_CONFIGS } from './typings';

const initialState: IAppState = {
  userInfo: {
    username: '',
    email: '',
    identityId: '',
    token: '',
    isSuperAdmin: false,
  },
  searchParamData: {},
  viewport: {
    width: window.innerWidth,
    mobileView: window.innerWidth < 768,
    tabView: window.innerWidth >= 768 && window.innerWidth <= 1024,
    desktopView: window.innerWidth > 1024,
  },
};

const hasNewSearchParams = (prevParam: any = {}, currentParam: any = {}) => {
  const prevParamLen: number = Object?.keys(prevParam)?.length;
  const currentParamLen: number = Object?.keys(currentParam)?.length;
  if (!prevParamLen || !currentParamLen || prevParamLen !== currentParamLen) return true;
  let isValid: boolean = false;
  Object?.entries(prevParam)?.forEach(([key, val]) => {
    if (!isValid) {
      if (currentParam?.[key] === undefined || `${val}` !== `${currentParam?.[key]}`) isValid = true;
    }
  });
  return isValid;
};

const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = {
        ...state.userInfo,
        ...action.payload,
      };
    },
    setViewport: (state, action) => {
      state.viewport = {
        ...state.viewport,
        ...action.payload,
      };
    },
    setSearchParamData: (state, action) => {
      if (action.payload) {
        const tempParams = {
          ...state.searchParamData,
          ...getTransformedURLParams({ ...action.payload }),
        };
        Object?.entries(tempParams)?.forEach(([k, v]: any) => !v && delete tempParams?.[k]);
        const prevParams = current(state?.searchParamData);
        const isRootPath: boolean = window?.location?.pathname === '/';
        const hasPolygons: boolean = tempParams?.polygons
          && Object?.keys(tempParams?.polygons)?.length;
        const hasDrawIds: boolean = !hasPolygons || isRootPath
          || getLocalStorage(LOCAL_STORAGE_KEY_CONFIGS.DRAW_IDS)?.[tempParams?.urlId] !== undefined;
        if (hasNewSearchParams(prevParams, tempParams) && hasDrawIds && tempParams?.urlId) {
          state.searchParamData = tempParams;
        }
      }
    },
  },
});

export const {
  setUserInfo,
  setViewport,
  setSearchParamData,
} = appReducer.actions;
export default appReducer.reducer;
