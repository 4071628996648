import { createSlice } from '@reduxjs/toolkit';
import { IPropertyDetail } from './typings';

const initialState: IPropertyDetail = {
  selectedRecord: undefined,
  showImagePopup: false,
  showDescPopup: false,
};

const propertyDetailReducer = createSlice({
  name: 'propertyDetail',
  initialState,
  reducers: {
    setSelectedRecord: (state, action) => {
      state.selectedRecord = { ...action.payload };
    },
    removeSelectedRecord: (state) => {
      state.selectedRecord = initialState?.selectedRecord;
    },
    setShowImagePopup: (state, action) => {
      state.showImagePopup = action.payload;
    },
    setShowDescPopup: (state, action) => {
      state.showDescPopup = action.payload;
    },
  },
});

export const {
  setSelectedRecord,
  removeSelectedRecord,
  setShowDescPopup,
  setShowImagePopup,
} = propertyDetailReducer.actions;
export default propertyDetailReducer.reducer;
