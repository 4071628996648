import { createSlice } from '@reduxjs/toolkit';
import { IDashboardProps } from './typings';

const initialState: IDashboardProps = {
  searchVal: '',
  pagination: {
    page: 1,
    size: 20,
    navigatedUpto: 1,
    nextTokens: [null],
  },
  newUserIds: [],
};

const dashboardReducer = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSearchVal: (state, action) => {
      state.searchVal = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination;
    },
    setNewUserIds: (state, action) => {
      state.newUserIds = [...(state?.newUserIds || []), action.payload];
    },
  },
});

export const {
  setSearchVal,
  setPagination,
  resetPagination,
  setNewUserIds,
} = dashboardReducer.actions;
export default dashboardReducer.reducer;
