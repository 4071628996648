import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setViewport } from 'features/app';

export interface IViewport {
  width: number
  mobileView: boolean
  tabView: boolean
  desktopView: boolean
}

export const useViewPort = () => {
  const viewport = useAppSelector((state) => state.app.viewport);
  const dispatch = useAppDispatch();

  const handleOnResize = () => {
    const currentWidth: number = window?.innerWidth;
    dispatch(setViewport({
      width: currentWidth,
      mobileView: currentWidth < 767,
      tabView: currentWidth > 767 && currentWidth < 1025,
      desktopView: currentWidth > 1024,
    }));
  };

  useEffect(() => {
    window.addEventListener('resize', handleOnResize, true);
    return () => window.removeEventListener('resize', handleOnResize, true);
  }, []);

  return { ...viewport };
};
