import React from 'react';
import { createGlobalStyle } from 'styled-components';

const BASE_FONT_SIZE = 16;
export const asRem = (size: number) => `${size / BASE_FONT_SIZE}rem`;

export const breakpoint = {
  mobile_max: `${asRem(767)}`,
  tab_min: `${asRem(768)}`,
  tab_medium: `${asRem(950)}`,
  tab_max: `${asRem(1024)}`,
  desktop_medium: `${asRem(1280)}`,
};

const GlobalStlyeWrapper = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    color: var(--color-15);
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  :root {
    --color-black: #000000;
    --color-white: #ffffff;
    --color-green: #219653;
    --color-lightgrey: #D3D3D3;
    --color-danger: #FF0000;
    --color-success: #22bb33;
    --color-1: #2A8C11;
    --color-2: #4F4F4F;
    --color-3: #E0E0E0;
    --color-4: #333333;
    --color-5: #705CBC;
    --color-6: #BDBDBD;
    --color-7: #828282;
    --color-8: #526EFF;
    --color-9: #8A2BE2;
    --color-10: #F2F2F2;
    --color-11: #2F80ED;
    --color-12: #C4C4C4;
    --color-13: #808080;
    --color-14: #767676;
    --color-15: #262424;
    --color-16: #CA302F;
    --color-17: #CECCCC;
    --color-18: #474747;
    --color-19: #696969;
    --color-20: #FAF9F8;
    --color-21: #F3F3F3;
    --color-22: #0E4287;
    --color-23: #E4E4E4;
    --color-24: #497FC7;
    --color-25: #A1A1A1;
    --color-26: #C74949;
    --color-27: #35A346;
    --color-28: #124E9C;
    --color-29: #DC9191;
    --color-30: #296DC8;
    --color-31: #F9F9F9;
    --color-32: #1A69DF;
    --color-33: #97C4FF;
    --color-34: #E06E6D;
    --color-35: #3A71C3;
    --color-36: #F5F5F5;
    --color-37: #CE2F2F;
    --color-38: #b40219;
    --color-39: #14142B;

    --color-rgb-white: 255, 255, 255;
    --color-rgb-black: 0, 0, 0;
    --color-rgb-5: 112, 92, 188;
    --color-rgb-6: 128, 128, 128;
    --color-rgb-7: 14, 66, 135;
  }

  .btn-click-effect {
    &:active {
      transform: translateY(${asRem(1)});
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
      & svg {
        opacity: 0.4;
      }
    }
  }

  #chartjs-tooltip {
    position: absolute;
    padding: ${asRem(10)} ${asRem(16)} ${asRem(15)};
    background-color: var(--color-22);
    color: var(--color-white);
    border-radius: ${asRem(4)};
    top: var(--top-pos);
    left: var(--left-pos);
    width: var(--tooltip-width);
    table {
      width: 100%;
      th {
        text-align: left;
        font-size: ${asRem(12.778)};
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -${asRem(0.383)};
        hr {
          height: ${asRem(0.5)};
          margin: ${asRem(4)} 0;
          background-color: var(--color-white);
        }
      }
      tbody {
        .price {
          font-size: ${asRem(20)};
          font-style: normal;
          line-height: ${asRem(25.556)};
          font-weight: 600;
          color: var(--color-white);
        }
        .address {
          display: flex;
          gap: ${asRem(2)};
          margin-top: ${asRem(8)};
          svg {
            width: ${asRem(14)};
            flex: 0 0 ${asRem(14)};
          }
        }
        a {
          text-decoration: none;
          font-size: ${asRem(14)};
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: var(--color-white);
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media print {
    body, .savelist {
      overflow: revert !important;
    }
    .hide-print {
      display: none !important;
    }
    .carousel {
      .carousel-arrow {
        display: none !important;
      }
    }
    .list-view {
      display: block !important;
      margin-bottom: ${asRem(30)} !important;
    }
    .savelist-chart-view {
      margin-left: ${asRem(50)} !important;
      margin-right: ${asRem(50)} !important;
    }
    .property-list {
      padding-left: ${asRem(50)} !important;
      padding-right: ${asRem(50)} !important;
    }
  }

  @page {
    margin: 0;
  }

  @media screen and (max-width: ${breakpoint.mobile_max}) {
    body {
      overflow: revert;
    }

    #chartjs-tooltip {
      padding: ${asRem(6)} ${asRem(8)};
      width: ${asRem(100)} !important;
      top: calc(var(--top-pos) + ${asRem(75)} + var(--mob-top-pos));
      left: calc(var(--left-pos) + var(--mob-left-pos));
      table {
        tbody {
          .price {
            font-size: ${asRem(10)};
          }
        }
      }
      .title, .address {
        display: none !important;
      }
    }
  }
`;

function GlobalStlyes() {
  return (
    <GlobalStlyeWrapper />
  );
}

export default GlobalStlyes;
