import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { getTransformedUsers } from './dashboardUtils';
import { IUserResponse, IUsersArg } from './typings';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  keepUnusedDataFor: 60 * 60 * 24,
  baseQuery: async ({
    endpoint,
    token,
    variables,
  }) => {
    try {
      const response = await fetch(`/backend/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(variables),
      });
      const data = await response.json();
      return { data };
    } catch (err) {
      return { error: err };
    }
  },
  endpoints: (builder) => ({
    getListUsers: builder.query<IUserResponse, IUsersArg>({
      query: ({ limit = 10, searchVal = '', nextToken = '' }) => {
        return {
          endpoint: 'users',
          variables: {
            limit,
            email: searchVal,
            ...(!searchVal ? { nextToken } : {}),
          },
        };
      },
      transformResponse: (response: any) => {
        return {
          ...response,
          users: getTransformedUsers(response?.users) || [],
        };
      },
    }),
    addUserToGroup: builder.mutation<any, IUsersArg>({
      query: ({ userId = '', groupName = '' }) => {
        return {
          endpoint: '/group/addUser',
          variables: { userId, groupName },
        };
      },
    }),
  }),
});

export const {
  useGetListUsersQuery,
  useAddUserToGroupMutation,
} = dashboardApi;
