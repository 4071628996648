import { createApi } from '@reduxjs/toolkit/dist/query/react';
import * as CustomQueries from 'graphql/customQueries';
import { formatDate, getInflationPrice } from 'utils/common';
import moment from 'moment';
import { client } from 'index';
import {
  IAdvertisement,
  IAdvertisementResponse,
  ICoordinate,
  IGeoJsonResponse,
  IIndexedTitle,
  IPropertiesApiState,
  IProperty,
  IPropertyArgs,
  ISale,
  ISaleResponse,
  ISearchSuggestion,
  ISearchSuggestionResponse,
} from './typings';
import {
  getFilterQueryParams,
  getTransformedCoordinateData,
  getTransformedGeoJsonData,
  getTransformedPropertyIdsData,
  getTransformedPropertiesData,
  getTransformedTitleData,
  getTransformedSearchSuggestion,
} from './propertyUtils';

export const propertyApi = createApi({
  reducerPath: 'propertyApi',
  keepUnusedDataFor: 60 * 60 * 24,
  baseQuery: async ({
    query,
    variables,
    queryName,
    context,
  }) => {
    try {
      const { data } = await client.query({ query, variables, context });
      return { data: data?.[queryName] };
    } catch (err) {
      return { error: err };
    }
  },
  endpoints: (builder) => ({
    getGeoJson: builder.query<IGeoJsonResponse, IPropertyArgs>({
      query: () => {
        return {
          query: CustomQueries.getGeoJson,
          queryName: 'geoJson',
        };
      },
      transformResponse: (response: string) => {
        return getTransformedGeoJsonData(response);
      },
    }),
    getCoordinates: builder.query<ICoordinate, IPropertyArgs>({
      query: ({ filter }) => {
        const hasAddress: boolean = !!filter?.selectedAddress?.length;
        return {
          query: CustomQueries.listMapProperties,
          queryName: 'listMapProperties',
          variables: {
            filter: getFilterQueryParams({
              ...filter,
              isLambda: true,
            }),
            source: ['id'],
            hasAddress,
            sort: { soldAt: -1 },
          },
        };
      },
      transformResponse: (response: string) => {
        return getTransformedCoordinateData(response);
      },
    }),
    getProperties: builder.query<IPropertiesApiState, IPropertyArgs>({
      query: ({ filter, identityId }) => {
        return {
          query: CustomQueries.listProperties,
          queryName: 'listProperties',
          variables: {
            ids: filter?.ids,
            sort: { recentDate: -1 },
          },
          ...(identityId ? {
            context: {
              headers: { 'X-Amz-id': identityId },
            },
          } : {}),
        };
      },
      transformResponse: (response: IPropertiesApiState) => {
        return getTransformedPropertiesData(response);
      },
    }),
    getPropertyById: builder.query<IProperty, IPropertyArgs>({
      query: ({ id, identityId }) => {
        return {
          query: CustomQueries.getProperty,
          queryName: 'getProperty',
          variables: { id },
          ...(identityId ? {
            context: {
              headers: { 'X-Amz-id': identityId },
            },
          } : {}),
        };
      },
      transformResponse: (response: IProperty) => {
        return {
          ...response,
          inflatedPrice: response?.availableForSale
            ? response?.recentPrice
            : getInflationPrice({
              year: moment.unix(response?.recentDate)
                ?.format('YYYY.MM')
                ?.replace('.', 'M'),
              price: response?.recentPrice,
              type: response?.propertyType,
              postcode: response?.postcode,
            }),
        };
      },
    }),
    getRelatedPropertyAdsById: builder.query<IAdvertisement[], IPropertyArgs>({
      query: ({ ids = [], identityId }) => {
        return {
          query: CustomQueries.listAds,
          queryName: 'listAds',
          variables: { ids },
          ...(identityId ? {
            context: {
              headers: { 'X-Amz-id': identityId },
            },
          } : {}),
        };
      },
      transformResponse: (response: IAdvertisementResponse) => {
        return response?.items?.map((item: IAdvertisement) => ({
          ...item,
          inflatedPrice: item?.availableForSale ? item?.price : getInflationPrice({
            year: formatDate(item?.whenCreated, 'YYYY.MM')?.replace('.', 'M'),
            price: item?.price,
            type: item?.propertyType,
            postcode: item?.postcode,
          }),
          availableForSale: true,
        }));
      },
    }),
    getRelatedPropertySalesById: builder.query<ISale[], IPropertyArgs>({
      query: ({ ids = [], identityId }) => {
        return {
          query: CustomQueries.listSales,
          queryName: 'listSales',
          variables: { ids },
          ...(identityId ? {
            context: {
              headers: { 'X-Amz-id': identityId },
            },
          } : {}),
        };
      },
      transformResponse: (response: ISaleResponse) => {
        return response?.items?.map((item: ISale) => ({
          ...item,
          inflatedPrice: getInflationPrice({
            year: formatDate(item?.registrationDate, 'YYYY.MM')?.replace('.', 'M'),
            price: item?.price,
            type: item?.propertyType,
            postcode: item?.postcode,
          }),
          availableForSale: false,
        }));
      },
    }),
    getPropertiesByAddress: builder.query<IPropertiesApiState, IPropertyArgs>({
      query: ({ filter }) => {
        return {
          query: CustomQueries.listPropertiesByAddress,
          queryName: 'listPropertiesByAddress',
          variables: {
            postcode: filter?.postcode,
            title: filter?.title,
          },
        };
      },
    }),
    getAllTitles: builder.query<IIndexedTitle, IPropertyArgs>({
      query: ({ filter, identityId }) => {
        return {
          query: CustomQueries.listTitles,
          queryName: 'listTitles',
          variables: {
            source: ['id', 'title'],
            ids: filter?.ids?.map((id: string) => Number(id)) || [],
          },
          ...(identityId ? {
            context: {
              headers: { 'X-Amz-id': identityId },
            },
          } : {}),
        };
      },
      transformResponse: (response: string) => {
        return getTransformedTitleData(response);
      },
    }),
    getPropertyIds: builder.query<{
      estimatedPricePerSqMeter: number,
      relatedPropertyIds: string[],
      items: IProperty[],
    }, IPropertyArgs>({
      query: ({ filter, identityId }) => {
        return {
          query: CustomQueries.listMapProperties,
          queryName: 'listMapProperties',
          variables: {
            source: [
              'id',
              'price',
              'soldAt',
              'availableForSale',
              'postcode',
              'propertyType',
              'squareMeters',
              'pricePerSquareMeter',
            ],
            filter: {
              and: [
                { key: 'id', type: 2, value: filter?.ids?.map((id: string) => Number(id)) },
              ],
            },
          },
          ...(identityId ? {
            context: {
              headers: { 'X-Amz-id': identityId },
            },
          } : {}),
        };
      },
      transformResponse: (response: string) => {
        return getTransformedPropertyIdsData(response);
      },
    }),
    getMarkerProperties: builder.query<IPropertiesApiState, IPropertyArgs>({
      query: ({ filter }) => {
        return {
          query: CustomQueries.listProperties,
          queryName: 'listProperties',
          variables: {
            ids: filter?.ids?.map((id: number) => String(id)),
          },
        };
      },
      transformResponse: (response: IPropertiesApiState) => {
        return getTransformedPropertiesData(response);
      },
    }),
    getSearchSuggestions: builder.query<ISearchSuggestion[], IPropertyArgs>({
      query: ({ filter }) => {
        return {
          query: CustomQueries.listStreetNames,
          queryName: 'listStreetNames',
          variables: {
            source: ['address', 'doorNo', 'count'],
            name: filter?.search,
          },
        };
      },
      transformResponse: (response: ISearchSuggestionResponse) => {
        return getTransformedSearchSuggestion(response);
      },
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useGetCoordinatesQuery,
  useGetPropertyByIdQuery,
  useGetPropertyIdsQuery,
  useGetPropertiesByAddressQuery,
  useGetRelatedPropertyAdsByIdQuery,
  useGetRelatedPropertySalesByIdQuery,
  useGetAllTitlesQuery,
  useGetGeoJsonQuery,
  useGetMarkerPropertiesQuery,
  useGetSearchSuggestionsQuery,
} = propertyApi;
