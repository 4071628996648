import { createSlice } from '@reduxjs/toolkit';
import { FILTER, filterDropdownConfigs } from 'features/property';
import { IFilterState } from './typings';

const initialState: IFilterState = {
  selectedFilter: {
    [FILTER.SOLD]: {
      min: filterDropdownConfigs?.[FILTER.SOLD]?.options?.[2]?.value?.min,
      max: filterDropdownConfigs?.[FILTER.SOLD]?.options?.[2]?.value?.max,
    },
    [FILTER.AVAILABLEFORSALE]: true,
    [FILTER.MATCHWITHADS]: true,
  },
};

const filterReducer = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    addSelectedFilter: (state, action) => {
      const tempData = {
        ...state.selectedFilter,
        ...action.payload,
      };
      if (!tempData?.[FILTER.TYPE]?.length) delete tempData[FILTER.TYPE];
      state.selectedFilter = tempData;
    },
    removeSelectedFilter: (state, action) => {
      const tempData = { ...state.selectedFilter };
      delete tempData[action.payload];
      state.selectedFilter = tempData;
    },
    clearAllSelectedFilter: (state) => {
      state.selectedFilter = {};
    },
  },
});

export const {
  addSelectedFilter,
  removeSelectedFilter,
  clearAllSelectedFilter,
} = filterReducer.actions;
export default filterReducer.reducer;
