import { createSlice } from '@reduxjs/toolkit';
import { ISearchState } from './typings';

const initialState: ISearchState = {
  selectedAddress: [],
  searchVal: '',
};

const searchReducer = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    setSearchVal: (state, action) => {
      state.searchVal = action.payload;
    },
  },
});

export const { setSelectedAddress, setSearchVal } = searchReducer.actions;
export default searchReducer.reducer;
