import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { IAuthArgs } from './typings';

export const authApi = createApi({
  reducerPath: 'authApi',
  keepUnusedDataFor: 60 * 60 * 24,
  baseQuery: async ({
    endpoint,
    token,
  }) => {
    try {
      const response = await fetch(`/backend/${endpoint}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      const data = await response.json();
      return { data };
    } catch (err) {
      return { error: err };
    }
  },
  endpoints: (builder) => ({
    login: builder.query<String, IAuthArgs>({
      query: ({ token }) => ({
        endpoint: 'login',
        token,
      }),
    }),
    logout: builder.query<String, IAuthArgs>({
      query: () => ({
        endpoint: 'logout',
      }),
    }),
  }),
});

export const {
  useLoginQuery,
  useLogoutQuery,
} = authApi;
